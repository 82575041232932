import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "template"];

  add(event) {
    event.preventDefault();

    const selectedDates = this.getSelectedDates();
    const newElement = this.createWorkingDayFields(selectedDates);
    this.containerTarget.insertAdjacentHTML('beforeend', newElement);
  }

  getSelectedDates() {
    return [...this.containerTarget.querySelectorAll('[data-controller="flatpickr"]')]
        .flatMap(field => field.value || []);
  }

  createWorkingDayFields(selectedDates) {
    const uniqueId = new Date().getTime();

    const templateContent = this.templateTarget.innerHTML;
    let newContent = templateContent.replace(/NEW_RECORD/g, uniqueId);

    if (selectedDates.length > 0) {
      const disableDates = JSON.stringify(selectedDates);
      newContent = newContent.replace(/data-flatpickr-disable="\[\]"/, `data-flatpickr-disable='${disableDates}'`);
    }

    return newContent;
  }

  remove(event) {
    event.preventDefault();
    const item = event.target.closest(".working-day");
    const destroyField = item.querySelector(".destroy-field");
    if (destroyField) {
      destroyField.value = 1;
      item.querySelector('[data-controller="flatpickr"]').value = '';
      item.style.display = "none";
    } else {
      item.remove();
    }
  }
}
